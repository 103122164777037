const CricketFightModule = () => import(/* webpackChunkName: "my-market-module" */ './views/Module.vue');
const CricketFight = () => import(/* webpackChunkName: "my-market" */ './views/CricketFight.vue');
const MyContests = () => import(/* webpackChunkName: "my-market" */ './views/MyContests.vue');
const MatchDetails = () => import(/* webpackChunkName: "my-market" */ './views/MatchDetails.vue');
const PlaceBet = () => import(/* webpackChunkName: "my-market" */ './views/PlaceBet.vue');
const MyContestBetDetails = () => import(/* webpackChunkName: "my-market" */ './views/MyContestBetDetails.vue');

const CricketFightRoutes = {
    path: '/',
    component: CricketFightModule,
    children: [
        {
            path: 'cricket-fight',
            name: 'cricket-fight',
            component: CricketFight
        },
        {
            path: 'cricket-fight/my-contests',
            name: 'my-contests',
            component: MyContests,
            meta:{
                title:'My Contests'
            }
        },
        {
            path: 'cricket-fight/:id',
            name: 'MatchDetails',
            component: MatchDetails
        },
        {
            path: 'cricket-fight/:id/place-bet',
            name: 'PlaceBet',
            component: PlaceBet
        },
        {
            path: 'cricket-fight/my-contests/:id',
            name: 'MyContestBetDetails',
            component: MyContestBetDetails
        }
    ],
}

export default CricketFightRoutes;