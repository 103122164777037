<template>
    <div class="headernavBarmenu-wrapper">
        <div class="container">
            <div class="headernavBarmenu-scroll">
                <ul class="headernavBarmenu-list">
                        <li v-if="checkEventManageforSingleType('sports-book')" :class="{ 'headernavBaractive': activeType == 1 }" @click="setActiveType(1)">
                            <a @click.prevent="gotoSportsBook()">
                                {{ translatedLangData('kabaddi', 'Kabaddi') }}
                                <div class="active-line"></div>
                            </a>
                        </li>

                        <li v-if="checkEventManageforSingleType('worli-matka')" :class="{ 'headernavBaractive': activeType == 13 }" @click="setActiveType(13)">
                            <router-link :to="{ name: 'matka' }">
                                {{ translatedLangData('matka', 'Matka') }}
                                <div class="active-line"></div>
                            </router-link>
                        </li>
 
                        <li :class="{ 'headernavBaractive': activeType == 2 }" @click="setActiveType(2)">
                            <a @click.prevent="clickOnGamestButton(aviatorItem)">
                                {{ translatedLangData('aviator', 'Aviator') }}
                                <div class="active-line"></div>
                            </a>
                        </li>
 
                        <li :class="{ 'headernavBaractive': activeType == 3 }">
                            <a @click.prevent="clickOnGamestButton(teenPattiItem, 3)">
                                {{ translatedLangData('teenpatti', 'Teenpatti') }}
                                <div class="active-line"></div>
                            </a>
                        </li>

                        <li :class="{ 'headernavBaractive': activeType == 4 }" @click="setActiveType(4)">
                            <router-link :to="{ name: 'games', params: { type: 'LIVE_CASINO' } }">
                                {{ translatedLangData('live-casino', 'Live Casino') }}
                                <div class="active-line"></div>
                            </router-link>
                        </li>

                        <li :class="{ 'headernavBaractive': activeType == 5 }" @click="setActiveType(5)">
                            <router-link :to="'/sports/' + 'cricket' + '/' + '4'">
                                {{ translatedLangData('sports', 'Sports') }}
                                <div class="active-line"></div>
                            </router-link>
                        </li>

                        <li v-if="checkEventManageforSingleType('sports-book')" :class="{ 'headernavBaractive': activeType == 6 }" @click="setActiveType(6)">
                            <a @click.prevent="gotoSportsBook()">
                                {{ translatedLangData('sportsbook', 'Sportsbook') }}
                                <div class="active-line"></div>
                            </a>
                        </li>

                        <li v-if="checkEventManageforSingleType('sports-book')" :class="{ 'headernavBaractive': activeType == 7 }" @click="setActiveType(7)">
                            <a @click.prevent="gotoSportsBook()">
                                {{ translatedLangData('virtual-sports', 'Virtual Sports') }}
                                <div class="active-line"></div>
                            </a>
                        </li>

                        <li :class="{ 'headernavBaractive': activeType == 8 }" @click="setActiveType(8)">
                            <router-link :to="{ name: 'games', params: { type: 'CASINO' } }">
                                {{ translatedLangData('casino', 'Casino') }}
                                <div class="active-line"></div>
                            </router-link>
                        </li>

                        <li :class="{ 'headernavBaractive': activeType == 9 }" @click="setActiveType(9)">
                            <router-link :to="{ name: 'shared-games', params: { type: 'poker' } }">
                                {{ translatedLangData('poker', 'Poker') }}
                                <div class="active-line"></div>
                            </router-link>
                        </li>

                        <li :class="{ 'headernavBaractive': activeType == 10 }" @click="setActiveType(10)">
                            <router-link :to="{ name: 'shared-games', params: { type: 'games' } }">
                                {{ translatedLangData('games', 'Games') }}
                                <div class="active-line"></div>
                            </router-link>
                        </li>
 
                        <li :class="{ 'headernavBaractive': activeType == 11 }">
                            <a @click.prevent="clickOnGamestButton(roullateItem, 11)">
                                {{ translatedLangData('non-stop-roulette', 'Non-stop Roulette') }}
                                <div class="active-line"></div>
                            </a>
                        </li>

                        <!-- Dreamwheel -->
                        <li :class="{ 'headernavBaractive': activeType == 12 }">
                            <a @click.prevent="clickOnGamestButton(dreamwheelItem, 12)">
                                {{ translatedLangData('dreamwheel', 'Dreamwheel') }}
                                <div class="active-line"></div>
                            </a>
                        </li>
                    </ul>

                <button class="menu-btn-nav" data-bs-toggle="modal" data-bs-target=".footermenumodal"
                    aria-label="Open Footer Menu">
                    <span class="fa-solid fa-chevron-down"></span>
                </button>

            </div>
        </div>
    </div>

    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#loginModalToggle"></div>
    <div ref="games_pop_1_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_1">
    </div>
    <div ref="games_pop_2_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_2">
    </div>
</template>

<script>
import IFrameModal from '@/modules/games/components/IFrameModal.vue';
import * as apiName from '../../services/urls';
import clickApi from '../../services/click-api';
import { setGameItem } from "@/shared/constants/shared-data";

export default {
    name: "SportSelection",
    components: {
        navigator: true,
        IFrameModal
    },
    inject: ['translatedLangData'],
    props: ['gameItem', 'success-fav'],
    emits: ['open-games-pop-up', 'open-iframe-modal'],
    data: () => ({
        non_custom_sports: [],
        custom_sports: [],
        clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
        openIframeModal: false,
        aviatorItem: null,
        teenPattiItem: null,
        roullateItem: null,
        dreamwheelItem: null,
        activeType: 0,
        eventManage: null
    }),
    created() {
        this.get_sportsList();
        let sectionsList = this.$store?.getters?.gamesData?.sections;
        this.aviatorItem = sectionsList?.filter(item => item.slug == 'aviator')[0]?.games?.[0];
        this.teenPattiItem = sectionsList?.filter(item => item.slug == 'teen-patti-home')[0]?.games?.[0];
        this.roullateItem = sectionsList?.filter(item => item.slug == 'roullate')[0]?.games?.[0];
        this.dreamwheelItem = sectionsList?.filter(item => item.slug == 'dream-wheel')[0]?.games?.[0];
        this.eventManage = this.$store?.getters?.eventManage;
    },
    computed:{
        sortedNonCustomSports(){
            const order = ['cricket', 'football', 'tennis'];
            return this.non_custom_sports.sort((a, b) => {
                const aIndex = order.indexOf(a.name.toLowerCase());
                const bIndex = order.indexOf(b.name.toLowerCase());

                if (aIndex !== -1 || bIndex !== -1) {
                    return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
                }
                return a.name.localeCompare(b.name);
            });
        }
    },
    methods: {
        gotoSportsBook(){
            if(this.checkIsLogin()){
                this.$router.push({name : 'sports-book'})
            }else{
                this.showErrorModalFunc(this.translatedLangData('login-to-cont','Please Login to continue'));
                this.$refs.open_login_modal.click();
            }
        },
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage?.find(item => item.type == type);
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;
        },
        checkEventManageforSports(sportId) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage?.find(item => item.type == 'sports');
                if(sportEventManage) {
                    let sportIds = sportEventManage.type_id.split(',');
                    return !sportIds.includes(sportId.toString());
                }
            }
            return true;    
        },
        setActiveType(type) {
            this.activeType = type;
        },
        closeModalIframe() {
            this.openIframeModal = false
        },
        checkInEventMange(type) {
            return this.$store.getters.eventManage ? this.$store.getters.eventManage?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        get_sportsList() {
            this.loading = true;
            clickApi.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if(this.checkEventManageforSports(all_sports[i].id)) {
                                if (all_sports[i].is_custom == 0) {
                                    this.non_custom_sports.push(all_sports[i])
                                } else {
                                    this.custom_sports.push(all_sports[i])
                                }
                                all_sports[i]?.name?.toLowerCase() === 'soccer' ? all_sports[i].name = 'Football' : '';
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {};
                        sport_list['custom'] = this.custom_sports;
                        sport_list['non_custom'] = this.sortedNonCustomSports;
                        this.$store.dispatch('setSportsData', sport_list);
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error);
                }
            });
        },
        clickOnGamestButton(item, type) {
            this.activeType = type;
            if (this.checkIsLogin()) {
                this.openGamesPopup(item);
            } else {
                this.showErrorModalFunc(this.translatedLangData('login-to-cont','Please Login to continue'));
                this.$refs.open_login_modal.click();
            }
        },
        userData() {
            return this.$store.getters.stateUser;
        },
        openGamesPopup(gameItem) {
            setGameItem(gameItem);
            switch (this.userData().currency) {
                case 1:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    }
                    else if (gameItem.only_hkd == 0) {
                        //this.openIframeModal = true;
                        //this.$refs.iframe_pop_modal_games_sports.click();
                        this.$emit('open-iframe-modal');
                    }
                    break;
                case 2:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    } else if (gameItem.only_hkd == 0) {
                        this.openIframeModal = true;
                        this.$refs.games_pop_2_modal.click();
                    }
                    break;
                case 3 || 4:
                    //this.openIframeModal = true;
                    //this.$refs.iframe_pop_modal_games_sports.click();
                    this.$emit('open-iframe-modal');
                    break;
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        getCapitalizedText(text) {
            return text[0].toUpperCase() + text.substring(1)
        },
        lowerCaseText(text) {
            return text.toLowerCase().replace(/\s+/g, '-');
        }
    }
};
</script>