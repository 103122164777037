<template>
    <div class="mobile-view-footer-background"></div>
            <div class="mobile-view-footer"> 
            <router-link :to="{ name: 'games', params: { type: 'CASINO' } }" class="mobile-view-footer-menu" 
                :class="{ active : $route.name === 'games' && $route.params.type === 'CASINO' && !isModalVisible}">
                <div class="casino" :class="{ show: $route.name == 'games' && $route.params.type == 'CASINO' }">
                    <div class="active-line"></div>
                    <img src="@/assets/images/footer-menu-casino.svg" alt="Casino menu icon" class="casino-img"> 
                    <span class="opt-title-inactive">{{ translatedLangData('casino-cap','CASINO') }}</span>
                </div>
            </router-link>
 
            <router-link :to="'/sports/cricket/4'" class="mobile-view-footer-menu"
                :class="{ active : $route.name === 'sports' && $route.params.type === 'cricket' && $route.params.id === '4' }">
                <div class="casino">
                    <div class="active-line"></div>
                    <img src="@/assets/images/footer-menu-sports.svg" alt="Sports menu icon" class="casino-img"> 
                    <span class="opt-title-inactive">{{ translatedLangData('sports-cap','SPORTS') }}</span>
                </div>
            </router-link>
 
            <div class="center">
                <router-link :to="'/cricket-fight'">
                    <div class="home-icon home-icon-active">
                        <img src="@/assets/images/cricket-fight.webp" alt="HOME">
                    </div>
                </router-link>
            </div>
 
            <a @click="gotoOpenBets()" class="mobile-view-footer-menu">
                <div class="casino bet-count-box">
                    <div class="active-line"></div>
                    <img src="@/assets/images/footer-live-tv-menu.svg" alt="LIVE BET" class="casino-img">
                    <span class="opt-title-inactive">{{ translatedLangData('open-bets-caps','OPEN BETS') }}</span>
                    <div class="open-bet-count" v-if="stateUser?.bets_count > 0">{{ stateUser?.bets_count }}</div>
                </div>
            </a>
 
            <button class="mobile-view-footer-menu bg-transparent " data-bs-toggle="modal" data-bs-target=".footermenumodal" 
                :class="{ 'active': isModalVisible }">
                <div class="casino">
                    <div class="active-line"></div>
                    <img src="@/assets/images/footer-menu.svg" alt="Menu icon" class="casino-img"> 
                    <span class="opt-title-inactive">{{ translatedLangData('menu','MENU') }}</span>
                </div>
            </button>
        </div>
    

    <!-- Modal Footer Menu -->
    <div  class="modal fade footermenumodal" id="footermenuModal" tabindex="-1" ref="footermenuModalRef" aria-labelledby="footermenuModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="menu-page">
                    <div class="header-menu">
                        <div class="left">
                            <div class="user">
                                <img  src="@/assets/images/login-person1.webp" alt="user">
                            </div>
                            <div class="welcome">   
                                <span class="hii-msg">{{translatedLangData('hi-there','Hi, There')}}</span>
                                <span class="wlcm-msg" v-if="checkIsLogin()">{{ this.userData?.userid }}</span>
                                <span class="wlcm-msg" v-else>{{`${translatedLangData('welcome-to','Welcome to')} ${siteSettings?.domain_name}`}}</span>
                            </div>
                        </div>
                        <div class="right">
                            <button v-if="!checkIsLogin()" class="reg-btn" data-bs-toggle="modal" href="#registerModalToggle">
                                <span>{{translatedLangData('register-now','Register Now,')}}</span>
                            </button>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src="@/assets/images/close-menu-icn.svg" alt="close-menu-icn">
                            </button>
                        </div>
                    </div>
                    <div class="top-options top-option-drpdown">
                        <div class="sports sports-drp-col">
                                <span class="title">{{translatedLangData('sports-cap','SPORTS')}}</span>
                                
                                <!-- Live -->
                                <router-link to="/">
                                    <div class="option" data-bs-dismiss="modal">
                                        <span class="rect" style="background: rgb(239, 113, 99);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-live-option.webp" alt="Live">
                                        </span>
                                        <span class="main-title">{{translatedLangData('live','Live')}}</span>
                                    </div>
                                </router-link>

                                <!-- Sports -->
                                <router-link :to="'/sports/' + 'cricket' + '/' + '4'">
                                    <div class="option" data-bs-dismiss="modal">
                                        <span class="rect" style="background: rgb(0, 144, 248);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-soccer.webp" alt="Sports">
                                        </span>
                                        <span class="main-title">{{translatedLangData('sports','Sports')}}</span>
                                    </div>
                                </router-link>

                                <!-- Sportsbook -->
                                <a @click="goTOSportBook()" v-if="checkEventManageforSingleType('sports-book')">
                                    <div class="option">
                                        <span class="rect" style="background: rgb(236, 42, 193);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-sportsbook-option.webp" alt="Sportsbook">
                                        </span>
                                        <span class="main-title">{{translatedLangData('sportsbook','Sportsbook')}}</span>
                                    </div>
                                </a>

                                <!-- Esports -->
                                <a @click="goTOSportBook()" v-if="checkEventManageforSingleType('sports-book')">
                                    <div class="option">
                                        <span class="rect" style="background: rgb(251, 191, 35);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-esports-option.webp" alt="Esports">
                                        </span>
                                        <span class="main-title">{{translatedLangData('esports','Esports')}}</span>
                                    </div>
                                </a>

                                <!-- Virtual Sports -->
                                <a @click="goTOSportBook()" v-if="checkEventManageforSingleType('sports-book')">
                                    <div class="option">
                                        <span class="rect" style="background: rgb(21, 175, 83);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-virtual-games-option.webp" alt="Virtual Sports">
                                        </span>
                                        <span class="main-title">{{translatedLangData('virtual-sports','Virtual Sports')}}</span>
                                    </div>
                                </a>
                            </div>


                            <div class="sports">
                                <span class="title">{{ translatedLangData('casino-cap', 'CASINO') }}</span>

                                <!-- Casino Link -->
                                <router-link :to="{ name: 'games', params: { type: 'CASINO' } }">
                                    <div class="option" data-bs-dismiss="modal">
                                        <span class="rect" style="background: rgb(158, 33, 178);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-casino-option.webp" alt="Casino">
                                        </span>
                                        <span class="main-title">{{ translatedLangData('casino', 'Casino') }}</span>
                                    </div>
                                </router-link>

                                <!-- Live Casino Link -->
                                <router-link :to="{ name: 'games', params: { type: 'LIVE_CASINO' } }">
                                    <div class="option" data-bs-dismiss="modal">
                                        <span class="rect" style="background: rgb(166, 244, 0);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-live-casino-option.webp" alt="Live Casino">
                                        </span>
                                        <span class="main-title">{{ translatedLangData('live-casino', 'Live Casino') }}</span>
                                    </div>
                                </router-link>

                                <!-- Poker Link -->
                                <router-link :to="{ name: 'shared-games', params: { type: 'poker' } }">
                                    <div class="option" data-bs-dismiss="modal">
                                        <span class="rect" style="background: rgb(179, 179, 179);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-poker-option.webp" alt="Poker">
                                        </span>
                                        <span class="main-title">{{ translatedLangData('poker', 'Poker') }}</span>
                                    </div>
                                </router-link>

                                <!-- Game Link -->
                                <router-link :to="{ name: 'shared-games', params: { type: 'games' } }">
                                    <div class="option" data-bs-dismiss="modal">
                                        <span class="rect" style="background: rgb(22, 241, 255);"></span>
                                        <span class="logo">
                                            <img src="@/assets/images/menu-game-option.webp" alt="Game">
                                        </span>
                                        <span class="main-title">{{ translatedLangData('games', 'Game') }}</span>
                                    </div>
                                </router-link>

                                <!-- Non-stop Roulette (keeping as a button for the click action) -->
                                <button @click="clickOnGamestButton(roullateItem)" class="option" data-bs-dismiss="modal">
                                    <span class="rect" style="background: rgb(205, 0, 74);"></span>
                                    <span class="logo">
                                        <img src="@/assets/images/menu-roulette-option.webp" alt="Non-Stop Roulette">
                                    </span>
                                    <span class="main-title">{{ translatedLangData('non-stop-roulette', 'Non-stop Roulette') }}</span>
                                </button>
                            </div>


                    </div>
                    <div class="other-options"> <span class="title">{{translatedLangData('other-games','OTHER GAMES')}}</span>
                        <div class="options-other-games">
                                <!-- Aviator -->
                                <button @click="clickOnGamestButton(aviatorItem)" class="game-opt" data-bs-dismiss="modal">
                                    <img src="@/assets/images/menu-aviator.webp" alt="Aviator">
                                    <span class="title-game-opt">{{ translatedLangData('aviator', 'Aviator') }}</span>
                                </button>

                                <!-- Teenpatti -->
                                <button @click="clickOnGamestButton(teenPattiItem)" class="game-opt" data-bs-dismiss="modal">
                                    <img src="@/assets/images/menu-teenPatti.webp" alt="Teenpatti">
                                    <span class="title-game-opt">{{ translatedLangData('teenpatti', 'Teenpatti') }}</span>
                                </button>

                                <!-- Dream Wheel -->
                                <button @click="clickOnGamestButton(dreamwheelItem)" class="game-opt" data-bs-dismiss="modal">
                                    <img src="@/assets/images/menu-dream-wheel.webp" alt="Dream Wheel">
                                    <span class="title-game-opt">{{ translatedLangData('dreamwheel', 'Dreamwheel') }}</span>
                                </button>
                            </div>

                    </div>
                    <div class="download-apk-section">
                        <div class="download-apk-card">
                            <img  src="@/assets/images/menu-dice.webp" alt="dice-bg" class="middle-dice">
                            <img  src="@/assets/images/menu-dice-bg.webp" alt="dice-bg" class="middle-dice-left">
                            <img  src="@/assets/images/menu-dice-bg2.webp" alt="dice-bg" class="middle-dice-right">
                            <div class="left download-platform-img">
                                <img  src="@/assets/images/cross-platform.png" alt="cross-platform">
                                <div class="left-text">
                                    <span class="upper"> {{translatedLangData('download-application','Download Application')}} </span>
                                    <span class="lower"> {{translatedLangData('instant-download-on-your-device','Instant download on your device')}} </span>
                                </div>
                            </div>
                            <div class="right">
                                <a class="install-btn" href="#install-apk-model" data-bs-toggle="modal">
                                    <span>{{translatedLangData('install-now','Install Now')}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="all-sports">
                        <span class="title-all-sports"> {{translatedLangData('all-sports','ALL SPORTS')}} </span>
                        <SportSelection data-bs-dismiss="modal" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DownloadApkModal></DownloadApkModal>
    <div ref="iframe_pop_modal_games_footer" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal_games_footer"></div>
     <div class="modal fade" id="iframe_pop_modal_games_footer" aria-hidden="true" aria-labelledby="loginModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" @click="closeModalIframe()">
            <IFrameModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" v-if="openIframeModal" />
        </div>
    </div>
    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#loginModalToggle"></div>
</template>

<script>
import moment from 'moment';
import * as sportApiName from '../../services/urls';
import { getUtcTimeStampFromDate, convertUTCDateToLocalDate, getSubtractedDaysDate } from '@/shared/utils/date-time';
import SportSelection from "@/shared/components/sport-selection/SportSelection.vue";
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
import { setGameItem } from "@/shared/constants/shared-data";
import IFrameModal from '@/modules/games/components/IFrameModal.vue';
import clickApi from '../../services/click-api';
import { mapGetters } from 'vuex';

export default {
    name: "Footer",
    data() {
        return {
            userData: null,
            // openBetsListLength: 0,
            isModalVisible: false,
            openIframeModal: false,
            filterOption: {
                type: 1,
                fromDate: moment(new Date(getSubtractedDaysDate(new Date(), 2))).format('YYYY-MM-DD'),
                toDate: moment(new Date()).format('YYYY-MM-DD'),
            },
            aviatorItem: null,
            teenPattiItem: null,
            dreamwheelItem: null,
            roullateItem: null,
            non_custom_sports: [],
            custom_sports: [],
            pageNo: 1,
            pageEntries: 50,
            eventManage: null
        }
    },
    inject:['translatedLangData'],
    components: {
        SportSelection,
        DownloadApkModal,
        IFrameModal
    },
    mounted() {
        this.userData = this.$store?.getters?.stateUser;
        this.$refs.footermenuModalRef.addEventListener('show.bs.modal', this.modalShown);
        this.$refs.footermenuModalRef.addEventListener('hide.bs.modal', this.modalHidden);
        this.eventManage = this.$store?.getters?.eventManage;
        // this.getOpenBetsServiceCall(1);
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        ...mapGetters([
			'stateUser'
		]),
        sortedNonCustomSports(){
            const order = ['cricket', 'football', 'tennis'];
            return this.non_custom_sports.sort((a, b) => {
                const aIndex = order.indexOf(a.name.toLowerCase());
                const bIndex = order.indexOf(b.name.toLowerCase());

                if (aIndex !== -1 || bIndex !== -1) {
                    return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
                }
                return a.name.localeCompare(b.name);
            });
        }
    },
    created(){
        this.get_sportsList();
        let sectionsList =  this.$store?.getters?.gamesData?.sections;
        this.aviatorItem = sectionsList?.filter(item => item.slug == 'aviator')[0]?.games?.[0];
        // this.teenPattiItem = sectionsList?.filter(item => item.slug == 'teenpatti')[0]?.games?.[0];
        this.teenPattiItem = sectionsList?.filter(item => item.slug == 'teen-patti-home')[0]?.games?.[0];
        this.dreamwheelItem = sectionsList?.filter(item => item.slug == 'dream-wheel')[0]?.games?.[0];
        this.roullateItem = sectionsList?.filter(item => item.slug == 'roullate')[0]?.games?.[0];
    },


    methods: {
        gotoOpenBets(){
            if(this.checkIsLogin()){
                this.$router.push('open-bets')
            }else{
                this.showErrorModalFunc(this.translatedLangData('login-to-cont','Please Login to continue'));
                this.$refs.open_login_modal.click();
            }
        },
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage?.find(item => item.type == type);
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;
        },
        checkEventManageforSports(sportId) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage?.find(item => item.type == 'sports');
                if(sportEventManage) {
                    let sportIds = sportEventManage.type_id.split(',');
                    return !sportIds.includes(sportId.toString());
                }
            }
            return true;    
        },
        goTOSportBook(){
            window.open('/sports-book','_self')
        },
        get_sportsList() {
            clickApi.get(sportApiName.GET_SPORTS).then(response => {
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if(this.checkEventManageforSports(all_sports[i].id)) {
                                if (all_sports[i].is_custom == 0) {
                                    this.non_custom_sports.push(all_sports[i])
                                } else {
                                    this.custom_sports.push(all_sports[i])
                                }
                                all_sports[i]?.name?.toLowerCase() === 'soccer' ? all_sports[i].name = 'Football' : '';
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {};
                        sport_list['custom'] = this.custom_sports;
                        sport_list['non_custom'] = this.sortedNonCustomSports;
                        this.$store.dispatch('setSportsData', sport_list);
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error);
                }
            });
        },
        closeModalIframe() {
            this.openIframeModal = false
        },
        clickOnGamestButton(item) {
            if (this.checkIsLogin()) {
                this.openGamesPopup(item);
            } else {
                this.showErrorModalFunc(this.translatedLangData('login-to-cont','Please Login to continue'));
                this.$refs.open_login_modal.click();
            }
        },
        userData() {
            return this.$store.getters.stateUser;
        },
        modalShown() {
            this.isModalVisible = true;
        },
        modalHidden() {
            this.isModalVisible = false;
        },
        openGamesPopup(gameItem) {
            setGameItem(gameItem);
            let userData = this.$store.getters.stateUser
            switch (userData.currency) {
                case 1:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    }
                    else if (gameItem.only_hkd == 0) {
                        this.openIframeModal = true;
                        this.$refs.iframe_pop_modal_games_footer.click();
                    }
                    break;
                case 2:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    } else if (gameItem.only_hkd == 0) {
                        this.$refs.games_pop_2_modal.click();
                    }
                    break;
                case 3 || 4:
                    this.openIframeModal = true;
                    this.$refs.iframe_pop_modal_games_footer.click();
                    break;
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        formatTimestamp(timestamp) {
            return moment(convertUTCDateToLocalDate(timestamp)).format("MMMM DD YYYY, h:mm:ss a");
        },
    },
    beforeDestroy() {
        // Remove event listeners when component is destroyed
        this.$refs.footermenuModalRef.removeEventListener('show.bs.modal', this.modalShown);
        this.$refs.footermenuModalRef.removeEventListener('hide.bs.modal', this.modalHidden);
    }
};
</script>
<style scoped>
.top-option-drpdown{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.sports-drp-col{
    min-width: 160px;
}

</style>