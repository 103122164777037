<template>
	<div v-if="showMarquee && checkForHeaderShow() && checkRoute() && cricketFightRoute() && siteSettings &&  siteSettings.announcement && siteSettings.announcement.msg" class="exch-marquee-notify-section">
        <marquee :style="{ color: siteSettings?.announcement?.color_code }">
			{{siteSettings.announcement.msg}}
        </marquee>
        <span @click="hideMarquee" class="exch-marquee-close-btn">
            <span class="fa fa-close"></span>
        </span>
    </div>
	<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
	</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>	
    <!-- header section -->
	 <template v-if="!underMaintenance">

		 <header class="header-wapper"  v-if="(checkRoute() && checkForHeaderShow())">
			 <Header></Header>
			 <SportSelectionHeader @open-iframe-modal="openIframeModalFunc()" v-if="$route.name !== 'sports-league' && cricketFightRoute()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></SportSelectionHeader>
		 </header>
	 </template>

	<router-view :key="$route.fullPath" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @changeLang="langMethod" @updateAuthToken="refreshAuthToken" v-if="siteSettings"  />

	<!-- footer start -->
	<footer v-if="(footerHideRoute() && cricketFightRoute()) && !underMaintenance">
		<Footer @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" v-if="!underMaintenance"></Footer>
	</footer>

	<!-- Login module -->
	<Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" :bonusData="bonusData"></Login>

    <!-- Sign up module -->
    <SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" :bonusData="bonusData"></SignUp>

	<!-- Forget password module -->
	<ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ForgetPassword>

	<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<GamesPop1Static />
	<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<Offers />
    <div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
		ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg" />
	</div>

	<div :class="{ show: showErrorModal }" style="z-index: 9999999999999999999999;"
		class="successfully-wrapper successfullytoaster-sec" id="wrongtoaster">
		<ErrorModal :message="errorMsg" />
	</div>

	<div ref="click_logout_msg" @click="showErrorModalFunc('You Are Logout From This Device.')" style="display: hidden;"></div>
	<div ref="iframe_pop_modal_games_sports" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal_games_sports"></div>

    <div class="modal fade" id="iframe_pop_modal_games_sports" aria-hidden="true" aria-labelledby="loginModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" @click="closeModalIframe()">
            <IFrameModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" v-if="openIframeModal" />
        </div>
    </div>
</template>

<script>
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
import Header from "@/shared/components/header/Header.vue";
import Footer from "@/shared/components/footer/Footer.vue";
import Login from "@/modules/authorization/components/Login.vue";
import SignUp from "@/modules/authorization/components/SignUp.vue";
import ForgetPassword from "@/modules/authorization/components/ForgetPassword.vue";
import SportSelectionHeader from "@/shared/components/sport-selection/SportSelectionHeader.vue";
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { setHeaders } from '@/shared/services/headers';
import moment from 'moment';
import ErrorModal from "./shared/components/modal/ErrorModal.vue";
import SuccessModal from "./shared/components/modal/SuccessModal.vue";
import { mapGetters } from 'vuex';
import { callFavouriteApis } from '@/shared/services/common-services';
import socketService from "./socket/sport-socket-service";
import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop1Static from "./shared/components/modal/GamesPop1Static.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import IFrameModal from "./modules/games/components/IFrameModal.vue";
import { computed } from 'vue';
import LanguageService from '@/shared/services/language-service.js';
import BetLoader from "@/modules/sports/components/BetLoader.vue";
import Maintenance from './shared/components/maintenance/Maintainence.vue'
import Offers from "./shared/components/header/Offers.vue";
import { Modal } from "bootstrap";

export default {
	name: "App",
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			showSuccessModal: false,
			successMsg: "",
			showErrorModal: false,
			errorMsg: "",
			refreshInit: false,
			scY: 0,
			scTimer: 0,
			showMarquee: true,
			openIframeModal: false,
			findActiveLang: null,
			langData: null,
			versionData:null,
			offerModal: '',
			bonusData: null,
			UpdatedVersion:null,
		}
	},
	provide() {
		return {
			translatedLangData: computed(() => this.translatedData)
		};
	},
	computed: {
		...mapGetters({
			captchaData: 'captchaData', siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections'
		}),
		routePath() {
			return this.$route.name;
		},
		siteSettings() {
			return this.$store.getters.siteSettings;
		},
		domainName() {
			return this.$store.getters.domainName;
		},
		siteVersion() {
			return this.$store.getters.siteVersion;
		},
		underMaintenance() {
			return this.$store.getters.underMaintenance;
		}
	},
	mounted() {
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		this.offerModal = new Modal("#offer_pop_up");
		window.emitLogoutMsg = this.$refs.click_logout_msg
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		//for add on all APIs
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		if (this.checkIsLogin()) {
			callFavouriteApis();
			if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters.stateUser.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
		} else {
			this.getBonusSettings();
		}

		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('scroll', this.onScroll);
	},
	async created() {
		await this.getSiteVersion();
		this.showMarquee = true;
		this.socketAllEvents();
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
	components: {
		Header,
		Footer,
		Login,
		SignUp,
		ErrorModal,
		SuccessModal,
		ForgetPassword,
		SportSelectionHeader,
		GamesPop1,
		GamesPop1Static,
		GamesPop2,
		IFrameModal,
		NotificationAlert,
		BetLoader,
		Maintenance,
		Offers,
	},
	methods: {
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				api.post(apiName.SUBSCRIBE_NOTIFICATION, data, { headers }).then(response => {
					this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.showErrorModalFunc(error[0]);
				});
		},
		openIframeModalFunc(){
			this.openIframeModal=true;
			this.$refs.iframe_pop_modal_games_sports.click();
		},
		closeModalIframe() {
            this.openIframeModal = false
        },
		translatedData(key, defaultVal) {
			if(this.langData?.[key]) {
				return this.langData?.[key];
			} else {
				return defaultVal;
			}
		},
		setTheme(theme) {
			localStorage.setItem('default_class', theme)
			document.documentElement.className = theme;
		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		async langMethod() {
			this.langData = null;
			let resData = this.siteSettings;
			this.allLanguages = this.siteSettings.language_translate_list;
			this.$store.dispatch('setLanguagesData', this.allLanguages);

			if (this.allLanguages) {
				this.findActiveLang = this.allLanguages.find(lang => lang.lang_code === resData.language_code);
				const selectedLanguageUrl = localStorage.getItem('selectedLanguageUrl');
				if (selectedLanguageUrl || ((this.findActiveLang && this.findActiveLang.lang_url) && resData.language_code != 'en') ) {	
					const langCode = selectedLanguageUrl || this.findActiveLang?.lang_url;
					const aws_url_language = resData.aws_url + '/' + langCode;
					this.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
				}
			}
		},
		async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			await api.get(apiName.getVersionStatus + "?domain=" + window.location.hostname).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						this.UpdatedVersion =  resData?.version;
						this.versionData = resData;
						const storedVersion = this.siteVersion;
						if (!this.UpdatedVersion) {
           				   this.UpdatedVersion = '1'; 
						}
						if (!storedVersion || String(storedVersion) !== String(this.UpdatedVersion) ||  !this.siteSettings) {
							await this.getSiteSettingsServiceCall();
						}
						else {
							this.siteSettingCommon(this.siteSettings);
							}
						const fromDateTime = new Date(resData?.maintenance?.from_date).getTime();
						const toDateTime = new Date(resData?.maintenance?.to_date).getTime();
						const currentDateTime = Date.now(); 
						if (resData.maintenance != null && (fromDateTime && toDateTime && fromDateTime <= currentDateTime && currentDateTime <= toDateTime)) {
							this.$store.dispatch('setUnderMaintenance', resData.maintenance);
							this.$store.dispatch('setGlobalLoader', false);
							this.$router.push({name:'Maintenance'});
							return;
						} else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
						this.$store.dispatch('setUnderMaintenance', null); 
						this.$store.dispatch('setGlobalLoader', false);
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		getBonusSettings() {
            api.get(apiName.GET_BONUS_SETTINGS + "?domain=" + window.location.hostname).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.bonusData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
		async getSiteSettingsServiceCall() {
			try {
				const response = await api.get(apiName.v1_SITE_SETTINGS + "?domain=" + window.location.hostname);
				this.$store.dispatch('setGlobalLoader', false);

				if (response && response.status == 200) {
					const responseData = response.data;

					if (responseData?.status === 0) {
						this.showErrorModalFunc(responseData.data.debug[0]);
					} else {
						let resData = responseData.data?.site_settings
						let casino = responseData.data?.casino
						this.$store.dispatch('setSettings', resData);
						this.$store.dispatch('setGamesData', casino);
						this.siteSettingCommon(resData);
						this.$store.dispatch('setLanguagesData', this.allLanguages);
					}
				}
			} catch (error) {
				this.$store.dispatch('setGlobalLoader', false);
				this.$store.dispatch('setSiteVersion', null);
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			}
		},

		async siteSettingCommon(siteSetting) {
			try {
				this.setManifest(siteSetting);
				const favElement = document.createElement('link');
				favElement.rel = 'icon';
				favElement.href = siteSetting.aws_url + siteSetting.storage_path.domain_image + siteSetting.fav_logo;
				document.head.insertAdjacentElement('beforeend', favElement);

				const title = document.getElementById("title");
				title.text = siteSetting.domain_name;
				let is_offer = localStorage.getItem("is_offer");
				if (siteSetting.front_css != null && siteSetting.front_css != '') {
				const styleElement = document.createElement('style');
				styleElement.type = 'text/css';
				styleElement.appendChild(document.createTextNode(siteSetting.front_css));
				document.head.insertAdjacentElement('beforeend', styleElement);
				this.convertCSSToJson(siteSetting.front_css);
				localStorage.setItem('default_class', 'CustomPropertiesTheme');
			} else if (localStorage.getItem('default_class')) {
				document.documentElement.className = localStorage.getItem('default_class');
				localStorage.setItem('CustomPropertiesTheme', null);
			} else {
				this.setTheme('purple');
				localStorage.setItem('CustomPropertiesTheme', null);
			}

			if (this.checkIsLogin()) {
				this.getWalletBalance();
			}
			await this.langMethod();
			if (this.checkIsLogin() && siteSetting.offer != null && is_offer == null && siteSetting?.business_type == 2) {
				this.offerModal.show();
			}
			this.$store.dispatch('setSiteVersion', this.UpdatedVersion);

			} catch (error) {
				this.$store.dispatch('setGlobalLoader', false);
				this.$store.dispatch('setSiteVersion', null);
				this.showErrorModalFunc(error);
			}
		},

		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path?.domain_image + resData.app_logo : resData.aws_url + resData.storage_path?.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path?.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.refreshInit = true;
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if(refresh) {
					router.go();
				}
				// setTimeout(function () {
				// 	this.getWalletBalance();
				// }.bind(this), 2000);
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkForHeaderShow() {
			if (this.routePath == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},

		convertCSSToJson(cssString) {
			const styles = {};
			const customProperties = {};

			// Extract custom properties from :root
			const rootMatch = cssString.match(/:root\s*{([^}]*)}/);
			if (rootMatch) {
				const rootDeclarations = rootMatch[1].split(';').filter(declaration => declaration.trim() !== '' && (declaration.includes('--primary-color') || declaration.includes('--secondary-color') ));
				rootDeclarations.forEach(declaration => {
					const [property, value] = declaration.split(':').map(part => part.trim());
					customProperties[property] = value;
				});
			}
			localStorage.setItem('CustomPropertiesTheme',JSON.stringify(customProperties));
		},


		showErrorModalFunc(message) {
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 2000);
		},
		showSuccessModalFunc(message) {
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 2000);
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
		checkRoute() {
			let routeName = this.$route.name
			let restrictedRoutes = [
				'account',
				'refer-earn',
				'rules',
				'term-and-condition',
				'settings',
				'changePassword',
				'profile',
				'edit-profile',
				'profit-loss',
				'profit-loss-detail',
				'statements',
				'statement-detail',
				'wallet',
				'wallet-history',
				'wallet-history-detail',
				'wallet-withdraw',
				'bonus',
				'bets',
				'match-market',
				'deposit-withdraw-report',
			]
			return !restrictedRoutes.includes(routeName)
		},
		footerHideRoute() {
			let routeName = this.$route.name
			let restrictedRoutes = [
				'account',
				'refer-earn',
				'rules',
				'term-and-condition',
				'settings',
				'changePassword',
				'profile',
				'edit-profile',
				'profit-loss',
				'profit-loss-detail',
				'statements',
				'statement-detail',
				'matka-details',
				'bonus',
				'bets',
				'sports-book',
				'deposit-withdraw-report',
				'wallet-gateway'
			]
			return !restrictedRoutes.includes(routeName)
		},
		cricketFightRoute() {
			let routeName = this.$route.name
			let restrictedRoutes = [
				'MatchDetails'
			]
			return !restrictedRoutes.includes(routeName)
		},
		hideMarquee() {
            this.showMarquee = !this.showMarquee;
        },
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};



</script>
