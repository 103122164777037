const AccountModule = () => import(/* webpackChunkName: "Account-module" */ './views/Module.vue');
const Account = () => import(/* webpackChunkName: "Account" */  "./components/Account.vue");
const Rules = () => import(/* webpackChunkName: "rules" */ "./components/Rules.vue");
const TermAndCondition = () => import(/* webpackChunkName: "term-and-condition" */ "./components/TermAndCondition.vue");
const Settings = () => import(/* webpackChunkName: "settings" */ './components/Settings.vue');
const ChangePassword = () => import(/* webpackChunkName: "settings" */ './components/ChangePassword.vue');

const AccountRoutes = {
    path: '/',
    component: AccountModule,
    children: [
        {
            path: "/account",
            name: "account",
            component: Account
        },
        {
            path: "/rules",
            name: "rules",
            component: Rules,
            meta: {
                title: "Rules"
            }
        },
        {
            path: "/term-and-condition",
            name: "term-and-condition",
            component: TermAndCondition,
            meta: {
                title: "Term & Condition"
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                title: "Settings"
            }
        },
        {
            path: '/change-password',
            name: 'changePassword',
            component: ChangePassword,
            meta: {
                title: "Change Password"
            }
        },
    ],
}

export default AccountRoutes;