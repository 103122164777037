import { io } from "socket.io-client";
const socket = io(process.env.VUE_APP_CLICK_API_SOCKET, {transports: ['websocket'], upgrade: false});

class SocketService {
    constructor() {
        this.socketIo = socket; 
        this.client = null;
        this.socketIo.connect();
        this.socketIo.on('connect', (id) => {
            this.client = id;
        });
    }
    
    on(event, listner) {
        this.socketIo.on(event, listner);
    }

    off(event) {
        this.socketIo.off(event);
    }

    emit(event, data) {
        this.socketIo.emit(event, data);
    }

}

export default new SocketService();