<template>
    <div class="modal fade loginModalToggleform" id="forgotpassModalToggle3" aria-hidden="true" aria-labelledby="loginModalToggleLabel3" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="e-p-header-bc1">
                    <div class="e-p-sections-bc1" v-if="siteSettings">
                        <a  href="/" data-bs-dismiss="modal" ref="close_btn">
                            <img  :src="siteSettings.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo" alt="logo" class="hdr-logo-bc-1">
                        </a>
                    </div>
                    <div class="e-p-sections-bc1">
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <button title="Register" class="e-p-section-item-bc2" data-bs-toggle="modal" href="#registerModalToggle">
                                <span>{{translatedLangData('register','Register')}}</span>
                            </button>
                            <div style="margin-left: 1rem; margin-right: 0.2rem;">
                                <button @click="refreshData()" type="button" class="btn-close" data-bs-dismiss="modal" id="forgotpassModalToggle3Btn"
                                    aria-label="Close"><span class="fa fa-close"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="e-p-content-bc1">
                    <div class="e-p-body-bc" style="overflow-y: auto;">
                        <div class="login-page-abc">
                            <div class="login-page" style="background: #000C24;">
                                <div class="login-box">
                                    <div class="login-card">
                                        <div class="login-card-header">
                                            <div class="login-text">
                                                <p>{{translatedLangData('forgot-password-heading','Forgot Password')}}</p>
                                            </div>
                                        </div>
                                        <div class="forgot-password-boxs">
                                            <div class="sms-box">
                                                <img  src="@/assets/images/star.webp" alt="star" class="star1">
                                                <div class="logo-box">
                                                    <img  src="@/assets/images/sms-logo.webp" alt="sms-logo">
                                                    <span>{{translatedLangData('sms','SMS')}}</span>
                                                    <div class="check-btn">
                                                        <span class="dot-img"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_wrapper">
                                            <div class="form_wrap">
                                                <form style="width: 100%;">
                                                    <div class="mobile-input">

                                                        <span>{{translatedLangData('mobile-number','Mobile Number')}}*</span>
                                                        <div id="sign-in-Input-Box" class="input-box">
                                                            <div class="country-code-flag-top-wrapper" ref="dropdownWrapper">
                                                                <div class="country-code-flag-top-sec" @click="clickCountry = !clickCountry" :class="timerRunning?'pointer-none':''">
                                                                    <span class="drp-btn">+{{form?.code}}</span>
                                                                    <img :src="selectedCountryflag" alt="" class="india-flag"> 
                                                                </div>
                                                                <ul class="country-code-flag-sec show-country-code" v-if="clickCountry">
                                                                    <li @click="selectCountry(item)" v-for="(item,index) in siteSettings?.country_code_list" :key="index">
                                                                        <span class="drp-btn">+ {{item?.phonecode}}</span>
                                                                        <img :src="item?.flag" alt="" class="india-flag"> 
                                                                    </li>
                                                                </ul>
                                                            </div>  
                                                            <!-- <span class="drp-btn">+91</span>
                                                            <img  src="@/assets/images/ind-flag-icon.webp" alt="" class="india-flag"> -->
                                                            <div class="str-line">
                                                                <img  src="@/assets/images/straight-line1.webp" alt="straight-line1">
                                                            </div>
                                                            <input id="login-mobile-number" type="text" :disabled="isOtpApiCalled" :class="{ 'enabled': !isOtpApiCalled, 'disabled': isOtpApiCalled }" 
                                                            v-model="form.mobileNo" minlength="10" maxlength="10" :placeholder="translatedLangData('enter-mobile-number','Enter Your Mobile Number')" formcontrolname="mobileNo" @input="checkMobileNoLength($event)">
                                                            <img  src="@/assets/images/close.svg" width="25px" alt="close" v-if="!phnValid && initialPhnIcon" class="check-vrf-img">
                                                            <img  src="@/assets/images/right-click-check.svg" alt="right-click-check" v-if="phnValid && initialPhnIcon" class="check-vrf-img">
                                                        </div>
                                                        <div class="justify-content-start set-err-text"
                                                            v-if="showErrorMobile && form.mobileNo == ''">
                                                            <p class="wrong-color">{{translatedLangData('please-enter-phone-number','Please enter mobile number.')}}</p>
                                                        </div>

                                                        <div class="forgot-btn-box">
                                                            <span v-if="timerRunning" style="margin-right: 10px;">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                                                            <button type="button" class="get-otp-btn" :disabled="loadingOtp || timerRunning || !phnValid" @click="sendOtpCall()"
                                                                :class="{ 'btn-loading': loadingOtp }"><span>{{ resendOtp ? translatedLangData('resend-otp','Resend OTP') : translatedLangData('get-otp','Get OTP') }}</span>
                                                            </button>
                                                        </div>

                                                        <div class="otp-sec mb-3" v-if="isOtpApiCalled">
                                                            <div class="number-header "><span>{{translatedLangData('otp','OTP')}}*</span>
                                                                <div class="otp-box-sec">
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[0]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 0)" ref="otp-box-0" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[1]" class="form-control" type="tel" maxlength="1"
                                                                            ref="otp-box-1" @keydown="handleOtpBoxKeyDown($event, 1)" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[2]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 2)" ref="otp-box-2" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[3]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 3)" ref="otp-box-3" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[4]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 4)" ref="otp-box-4" placeholder="_">
                                                                    </div>
                                                                    <div class="otp-box-con">
                                                                        <input v-model="form.otp[5]" class="form-control" type="tel" maxlength="1"
                                                                            @keydown="handleOtpBoxKeyDown($event, 5)" ref="otp-box-5" placeholder="_">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="justify-content-start set-err-text"
                                                                v-if="passwordValidation && getOtp().length < 6">
                                                                <p class="wrong-color">{{translatedLangData('please-enter-otp','Please Enter OTP.')}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="password-input"> <span>{{translatedLangData('password','Password')}}* </span>
                                                            <div class="changepassword-box">
                                                                <input  class="pass_log_id" :type="passwordFieldType" @input="validatePassword" minlength="8" maxlength="20" v-model="form.password" :placeholder="translatedLangData('enter-password','Enter your password')">
                                                                <span @click="toggleShow('password')" class="fa fa-fw field_icon toggle-password">
                                                                    <i class="fas" :class="(passwordFieldType=='text')?'fa-eye':'fa-eye-slash'"></i>
                                                                </span>
                                                            </div>
                                                            <div class="justify-content-start set-err-text "
                                                                v-if="passwordValidation && form.password == ''">
                                                                <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="passwordValid">
                                                                <img class="succ-wrong-img" v-if="!passwordValidation1" src="@/assets/images/request-right-icon.webp" alt="request-right-icon">
                                                                <img class="succ-wrong-img" v-if="passwordValidation1" src="@/assets/images/wrong-icon.webp" alt="wrong-icon">
                                                                <p :class="passwordValidation1 ? 'wrong-color':'success-color'">{{translatedLangData('must-be-between-8-20-characters','Must be between 8-20 characters!')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="passwordValid">
                                                                <img class="succ-wrong-img" v-if="!passwordValidation2" src="@/assets/images/request-right-icon.webp" alt="request-right-icon">
                                                                <img class="succ-wrong-img" v-if="passwordValidation2" src="@/assets/images/wrong-icon.webp" alt="wrong-icon">
                                                                <p :class="passwordValidation2 ? 'wrong-color':'success-color'">{{translatedLangData('must-be-alphanumeric','Must be Alphanumeric!')}}</p>
                                                            </div>
                                                            <div class="justify-content-start set-err-text pass-vali-div"
                                                                v-if="passwordValid">
                                                                <img class="succ-wrong-img" v-if="passwordValidation3" src="@/assets/images/request-right-icon.webp" alt="request-right-icon">
                                                                <img class="succ-wrong-img" v-if="!passwordValidation3" src="@/assets/images/wrong-icon.webp" alt="wrong-icon">
                                                                <p :class="!passwordValidation3 ? 'wrong-color':'success-color'">{{translatedLangData('must-contain-at-least-1-in-capital-case','Must Contain At Least 1 In Capital Case!')}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="password-input"> <span>{{translatedLangData('confirm-password','Confirm Password')}}* </span>
                                                            <div class="changepassword-box">
                                                                <input class="pass_log_id" @input="validateConfirmPassword" @keyup="checkPasswordMatch" :type="confPasswordFieldType" minlength="8" maxlength="20" v-model="form.confirmPassword" placeholder="Enter Confirm password">
                                                                <span @click="toggleShow" class="fa fa-fw field_icon toggle-password">
                                                                    <i class="fas" :class="(confPasswordFieldType=='text')?'fa-eye':'fa-eye-slash'"></i>
                                                                </span>
                                                            </div>
                                                            <div class="justify-content-start set-err-text">
                                                                <p v-if="passwordValidation && form.confirmPassword == ''" class="wrong-color">
                                                                    {{translatedLangData('please-enter-confirm-password','Please Enter Confirm Password.')}}</p>
                                                                <p v-if="confirmPasswordValidation && form.password != form.confirmPassword" class="wrong-color">
                                                                    {{translatedLangData('password-mismatch','Password Mismatch.')}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="forgot-btn-box">
                                                        <a href="#loginModalToggle" @click.prevent="BackBtnClick()" class="back-btn" data-bs-toggle="modal">
                                                            <span>{{translatedLangData('back','Back')}}</span>
                                                        </a>
                                                        <button @click="forgetPasswordCall()" type="button" :class="{ 'btn-loading': loadingUpdate }"
                                                            class="get-otp-btn">
                                                            <span>{{translatedLangData('save','Save')}}</span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="marquee-container">
                    <div class="marquee-content">
                        <div class="bonus-box">
                            <div class="bonus-card-box1">
                                <img loading="lazy" src="@/assets/images/star.svg" alt="star" class="star3">
                                <div class="bonus-inner-card1">
                                <img loading="lazy" src="@/assets/images/bonus-gift-img-2.webp" alt="bonus-gift-img-2" class="bonus-img">
                                <span>{{translatedLangData('deposite-bonus-500','500% Deposit Bonus')}}</span>
                                </div>
                                <img loading="lazy" src="@/assets/images/star.svg" alt="star" class="star4">
                            </div>
                            <div class="bonus-card-box">
                                <img loading="lazy" src="@/assets/images/star.svg" alt="star" class="star">
                                <div class="bonus-inner-card">
                                <img loading="lazy" src="@/assets/images/bonus-gift-img-1.webp" alt="bonus-gift-img-1" class="bonus-img">
                                <span>{{translatedLangData('extra-on-every-deposit-3','3% extra on Every deposit')}}</span>
                                </div>
                                <img loading="lazy" src="@/assets/images/star.svg" alt="star" class="star2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- -- Success Modal -- -->
    <div class="modal fade logoutmodal congratulation-modal" id="succesModal" aria-hidden="true" aria-labelledby="succesModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-end">
            <div class="modal-content transparent-modal-content">
                <div class="e-p-content-bc1">
                    <div class="e-p-body-bc" style="overflow-y: auto; margin: 0px">
                        <div class="login-page-abc">
                            <div class="login-page modal-blue-bg">
                                <div class="login-box">
                                    <div class="login-card">
                                        <div class="login-card-header exch-modal-popup-header-section">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin: 0 0 0 auto;">
                                                <i class="fa fa-close"></i>
                                            </button>
                                        </div>
                                        <div class="exch-cashout-body-center">
                                            <div class="cong-img-sec">
                                                <img src="@/assets/images/forgot-congratulation.svg" alt="forgot-congratulation">
                                            </div>
                                            <div class="congo-msg">
                                                <h1>{{translatedLangData('congratulations','Congratulations')}}!</h1>
                                                <p>{{translatedLangData('password-changed-successfully','Password changed successfully')}}!</p>
                                            </div>

                                        </div>
                                        <div class="forgot-btn-box">
                                            <button data-bs-target="#loginModalToggle" data-bs-toggle="modal" class="get-otp-btn text-white fw-bolder btn" :class="{ 'btn-loading': loading }">{{translatedLangData('goto-login','Go to Login')}}<span><b></b><b></b><b></b></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from "bootstrap"

export default {
    name: "ForgetPassword",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
            loadingOtp: false,
            loadingUpdate: false,
            showValidationError: false,
            showErrorMobile: false,
            passwordValid: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 5,
            seconds: 0,
            passwordFieldType:'password',
            confPasswordFieldType:'password',
            phnValid: false,
            initialPhnIcon: false,
            passwordValidation1 : false,
            passwordValidation2 : false,
            passwordValidation3 : false,
            passwordFlag: true,
            passwordValidation: false,
            clickCountry: false,
            selectedCountryflag: 'https://flagcdn.com/in.svg',
            confirmPasswordValidation: false,
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        getCaptchaData() {
            this.captchaData = this.$store.getters.captchaData
            return this.captchaData;
        },
    },
    mounted(){
        if(this.$route.name == 'forget-password' && !this.checkIsLogin()){
            let loginModal = new Modal('#forgotpassModalToggle3');
            loginModal.show();
        }
        else if(this.$route.name == 'forget-password' && this.checkIsLogin()){
            this.$router.push('/')
        }
        document.addEventListener('click', this.closeDropdownOnClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
    },
    methods: {
        validateConfirmPassword(){
            this.form.confirmPassword = this.form.confirmPassword.replace(/\s/g, '');
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },
        checkPasswordMatch(event) {
            let confirmPasswordVal = this.form.confirmPassword;
            if(confirmPasswordVal.length > 0) {
                confirmPasswordVal = event.target.value.replace(/\s/g, ''); // Remove any spaces from the input
                if (confirmPasswordVal != this.form.password) {
                    this.confirmPasswordValidation = true;
                } 
            } else {
                this.confirmPasswordValidation = false;
            }
        },
        closeDropdownOnClickOutside(event) {
            if (this.$refs.dropdownWrapper) {
                if (!this.$refs.dropdownWrapper.contains(event.target)) {
                    this.clickCountry = false;
                }
            }
        },
        selectCountry(item){
            this.form.code = item?.phonecode;
            this.selectedCountryflag = item?.flag;
            this.clickCountry = false;
        },
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';

            } else {
                this.confPasswordFieldType = (this.confPasswordFieldType === 'text') ? 'password' : 'text';
            }
        },
        refreshData() {
            this.form = {
                code: "91",
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
            this.showValidationError = false,
            this.showErrorMobile = false,
            this.passwordValidation = false
            this.isOtpApiCalled = false,
            this.resendOtp = false,
            this.resendOtpTimer = 0,
            this.timerRunning = false,
            this.minutes = 0,
            this.seconds = 0,
            this.passwordValidation1 = false,
            this.passwordValidation2 = false,
            this.passwordValidation3 = false
        },
        checkMobileNoLength(event) {
            this.initialPhnIcon = true;
            this.form.mobileNo = event.target.value.replace(/\D/g, ''); // Extract only numeric characters from the input value
            this.phnValid = this.form.mobileNo.length == 10;
        },
        validatePassword(){
           if(this.passwordFlag){
                this.passwordFlag = false;
                this.passwordValid = true;
            }
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            this.form.password = password.replace(/[^a-zA-Z0-9]/g, '');
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(password);
            const containsAlphabets = /^(?=.*[a-zA-Z]).*$/.test(password);
            const containsNumbers = /^(?=.*\d).*$/ .test(password);
            const containsSpaces = /\s/.test(password); // Check if password contains spaces
            this.passwordValidation1 = password.length < minLength || password.length > maxLength;
            this.passwordValidation2 = !containsAlphabets || !containsNumbers;
            this.passwordValidation3 = /[A-Z]/ .test(this.form.password);
            if (containsSpaces) {
                this.form.password = password.replace(/\s/g, '');
            }
        },
        BackBtnClick(){
            this.form.mobileNo = ""
            this.form.password = ""
            this.form.confirmPassword = ""
        },
        sendOtpCall() {
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
                "forget_password": 1,
                "getUserId": ""
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showErrorMobile = false
                        this.showSuccessModalFunc(response.data.message);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                        // this.closeModal();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },

        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },

        forgetPasswordCall() {
            if (!this.isOtpApiCalled)
                return
            let o = this.getOtp();

            this.passwordValidation = false;
            var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else if(!regex.test(this.form.password)){
                this.passwordValidation = true;
            } 
            else if (this.form.password != this.form.confirmPassword) {
                this.passwordValidation = true;
            }
            else if (o.length != 6 || this.form.password == '' || this.form.confirmPassword == '') {
                this.passwordValidation = true;
            }
            else{
                this.forgotServiceCall();
            }
        },
        async forgotServiceCall() {
            this.loadingUpdate = true;
            let data = {
                "phone": this.form.mobileNo,
                "cncode": this.form.code,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp()
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.data == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                            this.$store.dispatch('setCaptchaData', null);
                            captchaApi.getCaptchaDataServiceCall();
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.$store.dispatch('setCaptchaData', null);

                            let forrgotPassModalClose = document.getElementById("forgotpassModalToggle3Btn");
                            forrgotPassModalClose.click();

                            let succesModal = new Modal('#succesModal');
                            succesModal.show();

                            // captchaApi.getCaptchaDataServiceCall();
                            this.refreshData();
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },

    }
};
</script>