<template>
    <div class="home-page-loader" v-if="getGlobaLoader()">
        <img alt="logo" class="loader-img" id="logoImage" :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo">
        <img alt="spinner" class="spinner" id="loader" src="@/assets/images/loader.gif">
    </div>
    <div class="header-wapper-container">
        <div class="container">
            <div class="header-navbar">
                <div v-if="siteSettings">
                    <a @click="goToHome()" class="logo-a" href="/">
                        <img class="logo-imgtag" :src="siteSettings?.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo" alt="logo">
                    </a>

                </div>
                <div  v-if="!checkIsLogin()" class="navbar-button-sec">
                    <div class="login-btn-sec">
                        <button class="login-btn" data-bs-toggle="modal" href="#loginModalToggle">{{translatedLangData('log-in','Log in')}}</button>
                    </div>
                    <div class="register-btn-sec" v-if="siteSettings?.business_type != 1">
                        <button class="register-btn" data-bs-toggle="modal" href="#registerModalToggle">{{translatedLangData('register','Register')}}</button>
                    </div>
                </div>
                <div v-else class="exch-header-content">
                    <template v-if="cricketFightRoute()">
                        <div class="exch-header-content">
                            <div class="exch-bal-exp-btn-sec">
                                <a v-if="siteSettings?.business_type != 1" href="#javascript:void(0)" class="cricket-fight-deposit-btn"><span>{{translatedLangData('deposit','Deposit')}}</span></a> 
                                <a href="#javascript:void(0)" class="cricket-fight-fp-btn"><span>{{translatedLangData('fp','FP')}} :</span><span>{{ stateUser.balance }}</span></a> 
                            </div>
                        </div>
                    </template>
                    <template v-else> 
                            <a href="#" @click="getWalletGateway()">
                                <div class="balance-box">
                                    <span class="balance-amt" v-if="showBalanceProperty == 'false'"> {{ stateUser.balance }}</span>
                                    <span class="balance-amt" v-else> XXX.XX</span>
                                </div>
                            </a> 
                            <a href="#" @click="getWalletGateway()" v-if="siteSettings?.business_type != 1">
                                    <div class="deposit-button">
                                        <img 
                                            loading="lazy" 
                                            src="@/assets/images/wallet.webp" 
                                            alt="wallet">
                                        <span class="btn-text">{{ translatedLangData('deposit','Deposit') }}</span>
                                    </div>
                                </a>


                            <router-link to="/account">
                                <div class="person-logo">
                                    <img 
                                        loading="lazy" 
                                        src="@/assets/images/person-logo.webp"  
                                        alt="person"> 
                                </div>
                            </router-link>

                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Header",
    inject:['translatedLangData'],
    data() {
        return {
            showBalanceProperty: localStorage.getItem('showBalanceProperty') || 'false',
            showMarquee: true
        }
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings', stateUser : 'stateUser'
        })
    },
    methods: {
        getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        goToHome() {
            this.$router.push({ name: 'Home' });
        },
        getWalletGateway() {
            if(this.siteSettings?.business_type != 1){
                this.$router.push({name: 'wallet-gateway'});
            }
        },
        cricketFightRoute() {
			let routeName = this.$route.name
			let restrictedRoutes = [
				'cricket-fight',
				'MatchDetails',
				'PlaceBet',
				'my-contests',
				'MyContestBetDetails'
			]
			return restrictedRoutes.includes(routeName);
		},
    }
}
</script>
<style scoped>
.logo-a{
    display: flex;
    align-items: center;
    height: 46px;
}
.logo-imgtag{
    display: flex;
    height: 100%;
    object-fit: contain;
    padding: 2px 0;
    max-width: 100px !important;
}
.notify{
    display: flex;
    width: 100%;
    padding: 4px 12px;
}
.notification-msg{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.notification-msg span{
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}
</style>