<template>
    <div class="modal-dialog onload-modal  modal-dialog-centered">
        <div class="modal-content"  style="background-color: transparent !important;">
            <div class="container">
                <div class="recent-bank-sec">
                    <div class="recent-bank">
                        <div class="bank-icon">
                            <img  src="@/assets/images/right-icon.webp" alt="right-icon">
                        </div>
                        <div class="bank-con">
                            <h6>{{translatedLangData('success','Success')}}!</h6>
                            <span>{{ message }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
    inject:['translatedLangData']
}
</script>