import axios from 'axios';
import { headers } from '@/shared/services/headers';
import { TIME_5000 } from '@/shared/constants/services-const';
import handlerErrorFromApis from './error-handler';

const instance = axios.create({
    baseURL: process.env.VUE_APP_AUTH_API,
    timeout: TIME_5000,
    headers: headers
})


instance.interceptors.response.use(function (response) {
    return Promise.resolve(response);
}, function (error) {
    if (error) {
        return handlerErrorFromApis(error);
    }
});

export default instance;