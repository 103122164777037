<template>
    <div class="maintenance">
      <div class="maintenance_contain">
        <img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings.logo" alt="maintenance">
        <span class="pp-infobox-title-prefix">WE ARE COMING SOON</span>
        <div class="pp-infobox-title-wrapper">
          <h3 class="pp-infobox-title">The website is under maintenance!</h3>
        </div>
        <div class="maintenance-countdown-timer">
          <ul>
            <li><span>{{ days ? days : 0 }}</span> Days</li>
            <li><span>{{ hours ? hours : 0 }}</span> Hrs</li>
            <li><span>{{ minutes ? minutes : 0}}</span> Min</li>
            <li><span>{{ seconds ? seconds : 0}}</span> Sec</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'Maintenance',
    data() {
      return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      };
    },
    computed: {
      ...mapGetters({
        siteSettings: 'siteSettings',
      }),
      maintananceData() {
            return this.$store.getters.underMaintenance;
        },
    },
    created() {
    this.startTimer();
  },

  methods: {
  startTimer() {
    const updateInterval = 1000;
    setInterval(() => {
      const targetTime = new Date(this.maintananceData?.to_date).getTime();
      const currentTime = Date.now();
      const timeDifference = targetTime - currentTime;
      const oneSecond = 1000;
      const oneMinute = oneSecond * 60;
      const oneHour = oneMinute * 60;
      const oneDay = oneHour * 24;
      this.days = Math.floor(timeDifference / oneDay);
      this.hours = Math.floor((timeDifference % oneDay) / oneHour);
      this.minutes = Math.floor((timeDifference % oneHour) / oneMinute);
      this.seconds = Math.floor((timeDifference % oneMinute) / oneSecond);
    }, updateInterval);
    }
    }
  };
  </script>
<style scoped>
/******Under Maintenance Page css*******/
.maintenance {
    background-image: url("@/assets/images/main-1.jpg");
    /* @/assets/images/maintanance.png */
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}

.maintenance {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.maintenance {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.maintenance_contain {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;  
    padding: 15px;  
}
.maintenance_contain img {
    width: auto;
    max-width: 100%;
}
.pp-infobox-title-prefix {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-top: 30px;
    text-align: center;
}

.pp-infobox-title {
    color: #000000;
    font-weight: 700;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: block;
    word-break: break-word;  
}

.pp-infobox-description {
    color: #000000;
    font-weight: 400;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.pp-infobox-description p {
    margin: 0;
}

.title-text.pp-primary-title {
    color: #000000;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 50px;
    margin-bottom: 0px;
}

.maintenance-countdown-timer ul {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-top: 20px;
    padding: 0;
}
.maintenance-countdown-timer ul li {
    display: inline-block;
    background: #252525;
    color: #fff;
    padding: 10px;
    min-width: 80px;
    text-align: center;
    border-radius: 4px;
    line-height: normal;
    font-size: 14px;
    text-transform: uppercase;
}
.maintenance-countdown-timer ul li span {
    display: block;
    font-weight: 600;
    line-height: 26px;
    font-size: 24px;
    margin-bottom: 3px;
}

/******Under Maintenance Page css*******/
</style>