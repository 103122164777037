<template>
    <div class="modal fade popup-onloadmodal dark-modal-bg" id="static_games_pop_1" aria-hidden="true" refs="modal"
        aria-labelledby="welcomecasinoToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="exch-modal-popup-header-section">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
                <div class="exch-cashout-body-center">  
                    <div class="exch-casino-logo-img">
                        <img  src="@/assets/images/casino-img.webp" alt="casino-img">
                    </div>
                    <h3 class="exch-casino-title">Welcome to your new casino lobby</h3>
                    <div class="exch-point-sec">
                        <span class="exch-casino-title color-even">1</span>
                        <span class="exch-casino-title"> Point = </span>
                        <span class="exch-casino-title color-even"> 100</span>
                    </div>
                    <span class="sm-font font-gray-color">Checkout the exciting live casino tables, slots and much more, upto 200+ game and counting ...</span>
                </div>
                <button class="green-theme-btn exch-agree-btn" @click="yesAgreeMethodCall()">
                    <span>OK, I Agree !</span>
                </button>
            </div>
        </div>
    </div>

    <div ref="iframe_pop_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal"></div>

    <!-- Game Play Modal Start -->
    <div class="modal fade game-play-modal" id="iframe_pop_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div @click="closeModal()" class="modal-dialog modal-fullscreen" style="max-width: 100%;">
            <IFrameModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"  v-if="openIframeModal" />
        </div>
    </div>
</template>

<script>
import IFrameModal from '@/modules/games/components/IFrameModal.vue';

export default {
    name: 'GamesPop1',
    data() {
        return {
            openIframeModal: false,
        }
    },
    methods: {
        yesAgreeMethodCall() {
            this.openIframeModal = true;
            this.$refs.iframe_pop_modal.click();
        },
        closeModal() {
            this.openIframeModal = false
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
    components: {
        IFrameModal
    },
    mounted() {
        if (this.$route.name === 'games') {
            $(this.$el).find('#static_games_pop_1').modal('show');
        }
    },
}
</script>