const ReferEarnModule = () => import(/* webpackChunkName: "refer-earn-module" */ './views/Module.vue');
const ReferEarn = () => import(/* webpackChunkName: "refer-earn" */ './views/ReferEarn.vue');

const ReferEarnRoutes = {
    path: '/',
    component: ReferEarnModule,
    children: [
        {
            path: 'refer-earn',
            name: 'refer-earn',
            component: ReferEarn,
            meta: {
                title: "Refer and Earn"
            }
        }
    ],
}

export default ReferEarnRoutes;