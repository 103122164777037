<template>
    <div class="page-loader bet-loader" v-if="betLoader">
        <img  src="@/assets/images/loader.gif" class="loader-spinner-img">
    </div>
</template>

<script>
export default {
    name: "BetLoader",
    props: ['betLoader']
}
</script>

<style scoped>
.bet-loader {
    z-index: 9999999999;
    position: fixed;
    width: 100%;
    text-align: center;
    height: 100%;
    top: 0;
}
</style>