<template>
    <div v-if="gameItem" class="modal-content">
        <div class="e-p-header-bc1">
            <div class="e-p-sections-bc1">
                <img @click="closeModal()" ref="close_button" data-bs-dismiss="modal" aria-label="Close" src="@/assets/images/home-icon.webp" alt="logo" style="height: 24px;">
                <strong class="iframe-game-title">{{ gameItem?.game_name }}</strong>
            </div>
            <div class="e-p-sections-bc1">
                <div style="display: flex; align-items: center; justify-content: center;">
                    <div style="margin-left: 1rem; margin-right: 0.2rem;">
                        <button ref="close_button" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()">
                            <span class="fa fa-close"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="e-p-content-bc1">
            <div class="e-p-body-bc" style="overflow-y: auto;">
                <div v-if="!loading" class="game-iframe-sec">
                    <div v-if="this.iFrameUrl" class="iframe-content">
                        <iframe :src="iFrameUrl" frameborder="0"
                            style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                            height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            title="Tr&amp;eacute;ning TEXT"></iframe>
                    </div>
                </div>
                <div v-else>
                    <transition name="fade">
                        <div class="preloader d-flex align-items-center justify-content-center">
                            <div class="cssload-container">
                                <div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getGameItem, setGameItem } from '@/shared/constants/shared-data';
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'IFrameModal',
    inject:['translatedLangData'],
    data() {
        return {
            loading: false,
            iFrameUrl: null,
            gameItem: null,
        }
    },
    mounted() {
        this.gameItem = this.getGameItemHere();
        if(this.gameItem) {
            if (this.gameItem.provider && this.gameItem.provider == 'poker') {
                this.getLivePockerUrl()
            }
            else {
                this.getDreamCasinoLoadGames()
            }
        }
    },
    methods: {
        getLivePockerUrl() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.GET_LIVE_POCKER_URL + this.gameItem.game_code + '?dtype=m', {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc(this.translatedLangData('url-notfound','URL not found.'))
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeModal();
                }
            });
        },
        getDreamCasinoLoadGames() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.DREAM_CASINO + this.gameItem.game_code + '/' + this.gameItem.sm_id, {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc(this.translatedLangData('url-notfound','URL not found.'))
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeModal()
                }
            });
        },
        closeModal() {
            setTimeout(() => {
                setGameItem(null);
                this.$refs?.close_button?.click();
            }, 400);
        },
        getGameItemHere() {
            return getGameItem();
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>
<style>
.iframe-content iframe {
    position: inherit !important;
    height: 100vh !important;
}
</style>