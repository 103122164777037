<template>
    <div class="modal fade popup-onloadmodal dark-modal-bg" id="games_pop_1_static" aria-hidden="true" refs="modal"
        aria-labelledby="welcomecasinoToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="exch-modal-popup-header-section">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
                <div class="exch-cashout-body-center">  
                    <div class="exch-casino-logo-img">
                        <img  src="@/assets/images/casino-img.webp" alt="casino-img">
                    </div>
                    <h3 class="exch-casino-title">Welcome to your new casino lobby</h3>
                    <div class="exch-point-sec">
                        <span class="exch-casino-title color-even">1</span>
                        <span class="exch-casino-title"> Point = </span>
                        <span class="exch-casino-title color-even"> 100</span>
                    </div>
                    <span class="sm-font font-gray-color">Checkout the exciting live casino tables, slots and much more, upto 200+ game and counting ...</span>
                </div>
                <button class="green-theme-btn exch-agree-btn" data-bs-dismiss="modal">
                    <span>OK, I Agree !</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GamesPop1'
}
</script>