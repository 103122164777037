<template>
    <!-- Notification alert Modal -->
	<div class="modal fade notification-alert-modal" id="notification-alert" tabindex="-1" ref="notificationAlert"
		aria-labelledby="notificationModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<div class="notifi-section">
						<div class="notifi-icon">
							<img src="@/assets/images/notification-alert.svg" alt="notification-alert">
						</div>
						<div class="notify-theme-info">
							<h3>Turn on the Notifications</h3>
							<div class="notify-justify-btn">
								<button class="btn cancel-but" data-bs-dismiss="modal"
									aria-label="Close" @click="cancelNotifications">Cancel</button>
								<button class="btn thm-but" data-bs-dismiss='modal' @click="allowNotifications">Allow</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Notification alert Modal -->
</template>
<script>
export default{
    emits: ['allowNotifications','cancelNotifications'],
    methods:{
        allowNotifications() {
            this.$emit('allowNotifications');
        },
        cancelNotifications() {
            this.$emit('cancelNotifications');
        }
    }

}
</script>