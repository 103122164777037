import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import "./assets/css/bootstrap.min.css";
import "./assets/css/chat.css";
import "./assets/css/chatresponsive.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import './registerServiceWorker';

router.beforeEach((to, from) => {
    if(window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'changePassword'){
        window.router.push({ name: 'changePassword'});
    }
})


router.beforeEach((to, from, next) => {
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
        } else {
            next();
        }
    }
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.mount("#app");

app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";