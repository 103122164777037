const ProfileModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ './views/Profile.vue');
const EditProfile = () => import(/* webpackChunkName: "profile" */ './components/editProfile.vue');

const ProfileRoutes = {
    path: '/',
    component: ProfileModule,
    children: [
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                title: "Profile"
            }
        },
        {
            path: '/edit-profile',
            name: 'edit-profile',
            component: EditProfile,
            meta: {
                title: "Edit Profile"
            }
        }
    ],
}   

export default ProfileRoutes;