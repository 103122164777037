const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "wallet" */ './views/Wallet.vue');
const WalletHistory = () => import(/* webpackChunkName: "wallet-history" */ './views/WalletHistory.vue');
const WalletHistoryDetail = () => import(/* webpackChunkName: "wallet-history-detail" */ './views/WalletHistoryDetail.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "add-account" */ './views/WalletWithdraw.vue');
const WalletGateway = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WalletGateway.vue');


const WalletRoutes = {
    path: '/',
    component: WalletModule,
    children: [
        {
            path: 'wallet',
            name: 'wallet',
            component: Wallet,
        },
        {
            path: 'wallet-history',
            name: 'wallet-history',
            component: WalletHistory,
        },
        {
            path: 'wallet-history-detail/:type',
            name: 'wallet-history-detail',
            component: WalletHistoryDetail,
        },
        {
            path: 'wallet-withdraw',
            name: 'wallet-withdraw',
            component: WalletWithdraw,
        },
        {
            path: 'wallet-gateway',
            name: 'wallet-gateway',
            component: WalletGateway,
          }
    ],
}

export default WalletRoutes;