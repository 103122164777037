const SportsModule = () => import(/* webpackChunkName: "sports-module" */ './views/Module.vue'); 
const Sports = () => import(/* webpackChunkName: "sports" */ './views/Sports.vue');
const SportEventDetail = () => import(/* webpackChunkName: "sport-event-detail" */ './views/SportEventDetail.vue');
const SportsBook = () => import(/* webpackChunkName: "sport-book" */ './views/SportsBook.vue');
const SportsLeague = () => import(/* webpackChunkName: "sports-league" */ './views/Leagues.vue');

const SportsRoutes =   {
    path: '/',
    component: SportsModule,
    children: [
        {
            path: 'sports/:type/:id',
            name: 'sports',
            component: Sports
        },
        {
            path: 'sports-event-detail/:event_id',
            name: 'sports-event-detail',
            component: SportEventDetail
        },
        {
            path: 'sports-book',
            name: 'sports-book',
            component: SportsBook
        },
        {
            path: 'sports/:type/league',
            name: 'sports-league',
            component: SportsLeague
        },
    ],
}

export default SportsRoutes;