<template>
    <div class="modal fade loginModalToggleform" id="loginModalToggle" aria-hidden="true" aria-labelledby="loginModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="e-p-header-bc1">
                    <div class="e-p-sections-bc1" v-if="siteSettings">
                        <a  href="/" data-bs-dismiss="modal" ref="close_btn">
                            <img  :src="siteSettings.aws_url + siteSettings.storage_path?.domain_image + siteSettings.logo" alt="logo" class="hdr-logo-bc-1">
                        </a>
                    </div>
                    <div class="e-p-sections-bc1">
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <button title="Register" class="e-p-section-item-bc2" data-bs-toggle="modal" href="#registerModalToggle" v-if="siteSettings?.business_type != 1">
                                <span>{{translatedLangData('register','Register')}}</span>
                            </button>
                            <div style="margin-left: 1rem; margin-right: 0.2rem;">
                                <button ref="close_btn" @click="refreshData(form.type)" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <span class="fa fa-close"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="e-p-content-bc1">
                    <div class="e-p-body-bc" style="overflow-y: auto;">
                        <div class="login-page-abc">
                            <div class="login-page">
                                <div class="login-box">
                                    <div class="login-card">
                                        <div class="login-card-header">
                                            <span>{{translatedLangData('log-in','Log in')}}</span>
                                            <div class="login-text">
                                                <p class="text-capitalize">{{translatedLangData('welcome-domain',`Welcome to ${siteSettings?.domain_name}`)}}</p>
                                                <img  src="@/assets/images/hand-logo.webp" alt="waving-hand" style="height: 24px; width: 24px;">
                                            </div>
                                        </div>
                                        <div class="form_wrapper">
                                            
                                            <input type="radio" class="radio" name="radio" id="phone" :checked="form.type == 1" />
                                            <input type="radio" class="radio" name="radio" id="userid" :checked="form.type == 2" />
                                            <label class="tab phone_tab" for="phone" @click="form.type = 1,refreshData(form.type)">{{translatedLangData('mobile-number','Mobile Number')}}   </label>
                                            <label class="tab userid_tab" for="userid" @click="form.type = 2,refreshData(form.type)">{{translatedLangData('user-id','User ID')}}  </label>
                                            <span class="shape"></span>
                                            <div class="form_wrap">

                                                <form style="width: 100%;" class="animateSignInFormMobileNo form_fild phone_form">
                                                    <div class="mobile-input"> <span>{{translatedLangData('mobile-number','Mobile Number')}}*</span>
                                                        <div id="sign-in-Input-Box" class="input-box">
                                                            <div class="country-code-flag-top-wrapper" ref="dropdownWrapper">
                                                                <div class="country-code-flag-top-sec" @click="clickCountry = !clickCountry">
                                                                    <span class="drp-btn">+{{form?.code}}</span>
                                                                    <img :src="selectedCountryflag" alt="" class="india-flag"> 
                                                                </div>
                                                                <ul class="country-code-flag-sec show-country-code" v-if="clickCountry">
                                                                    <li @click="selectCountry(item)" v-for="(item,index) in siteSettings?.country_code_list" :key="index">
                                                                        <span class="drp-btn">+ {{item?.phonecode}}</span>
                                                                        <img :src="item?.flag" alt="" class="india-flag"> 
                                                                    </li>
                                                                </ul>
                                                            </div> 
                                                            <!-- <span class="drp-btn">+91</span>

                                                            <img  src="@/assets/images/ind-flag-icon.webp" alt="ind-flag-icon" class="india-flag"> -->
                                                            <div class="str-line">
                                                                <img  src="@/assets/images/straight-line1.webp" alt="straight-line1">

                                                            </div>
                                                            <input id="login-mobile-number" @input="checkMobileNoLength($event)" v-if="form.type == 1" type="text" v-model="form.mobileNo" minlength="10" maxlength="10" :placeholder="translatedLangData('enter-mobile-number','Enter Your Mobile Number')">
                                                            <img  src="@/assets/images/close.svg" width="25px" alt="close" v-if="!phnValid && initialPhnIcon" class="check-vrf-img">
                                                            <img  src="@/assets/images/right-click-check.svg" alt="right-click-check" v-if="phnValid && initialPhnIcon" class="check-vrf-img">
                                                        </div>
                                                        <div v-if="showValidationError && getId() == ''" class="justify-content-start set-err-text">
                                                            <p class="wrong-color">{{translatedLangData('please-enter-phone-number','Please enter mobile number')}}</p>
                                                        </div>

                                                        <div class="password-input">
                                                            <span>{{translatedLangData('password','Password')}}* </span>
                                                            <div class="changepassword-box">
                                                                <input  class="pass_log_id" :type="passwordFieldType" @input="validatePassword" v-model="form.password" :placeholder="translatedLangData('enter-password','Enter your password')">
                                                                <span @click="toggleShow('password')" class="fa fa-fw field_icon toggle-password">
                                                                    <i class="fas" :class="(passwordFieldType=='text')?'fa-eye':'fa-eye-slash'"></i>
                                                                </span>
                                                            </div>
                                                            <div v-if="showValidationError && form.password == ''" class="justify-content-start set-err-text">
                                                                <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="forgot-check-box">
                                                            <div class="mak-gin form-check cheak-flex">
                                                                <div class="cheak">
                                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                    <label class="form-check-label" for="exampleCheck1">{{translatedLangData('keep-me-logged-in','Keep Me Logged In')}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="forgot" v-if="siteSettings?.business_type != 1">
                                                                <button type="button" data-bs-target="#forgotpassModalToggle3" data-bs-toggle="modal" class="btn-forgot-password bg-transparent">
                                                                    <span>{{ translatedLangData('forgot-password', 'Forgot Password?') }}</span>
                                                                </button>
                                                              
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="forgot-btn-box">
                                                        <button type="button" @click="demoUserLogin()" class="back-btn btn text-white" :class="{ 'btn-loading': demoLoading }">
                                                            {{translatedLangData('demo-login','Demo Log in')}} <span><b></b><b></b><b></b></span>
                                                        </button>
                                                        <button @click.prevent="signInMethodCall()" class="get-otp-btn btn" :disabled="!phnValid" :class="{ 'btn-loading': loading }">
                                                            <span>{{translatedLangData('log-in','Log in')}}</span>
                                                        </button>
                                                    </div>
                                                </form>
                                                <form style="width: 100%;" class="animateSignInFormUserId form_fild userid_form">
                                                    <div class="whatsup-login-box">
                                                        <div class="mobile-input">
                                                            <span>{{translatedLangData('user-id','User ID')}}*</span>
                                                            <div class="input-box">
                                                                <div></div>

                                                                <img  alt="user" src="@/assets/images/login-person.webp">

                                                                <div class="str-line">
                                                                    <img  src="@/assets/images/straight-line1.webp" alt="user">
                                                                </div>
                                                                <input type="text" @input="handleInputUserID($event)" formcontrolname="userId" v-model="form.userId" style="color: #FFF;" :placeholder="translatedLangData('enter-user-id','Enter User ID')">
                                                            </div>
                                                            <div v-if="showValidationError && getId() == ''" class="justify-content-start set-err-text">
                                                                <p class="wrong-color">{{translatedLangData('please-enter-user-id','Please Enter User ID.')}}</p>
                                                            </div>
                                                            <div class="password-input">
                                                                <span>{{translatedLangData('password','Password')}}* </span>
                                                                <div class="changepassword-box">
                                                                    <input  class="pass_log_id" :type="passwordFieldType" @input="validatePassword" v-model="form.password" :placeholder="translatedLangData('enter-password','Enter Your Password')">
                                                                    <span @click="toggleShow('password')" class="fa fa-fw field_icon toggle-password">
                                                                        <i class="fas" :class="(passwordFieldType=='text')?'fa-eye':'fa-eye-slash'"></i>
                                                                    </span>
                                                                </div>
                                                                <div v-if="showValidationError && form.password == ''" class="justify-content-start set-err-text">
                                                                    <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                                                                </div>
                                                            </div>

                                                            <Captcha :showValidationError="showValidationError" :form="form" />

                                                            <div class="forgot-check-box">
                                                                <div class="mak-gin form-check cheak-flex">
                                                                    <div class="cheak">
                                                                        <input type="checkbox" class="form-check-input" id="exampleCheck2">
                                                                        <label class="form-check-label" for="exampleCheck2">{{translatedLangData('keep-me-logged-in','Keep Me Logged In')}}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="forgot" v-if="siteSettings?.business_type != 1"> 
                                                                        <a href="#" data-bs-target="#forgotpassModalToggle3" data-bs-toggle="modal">
                                                                            <span>{{translatedLangData('forgot-password', 'Forgot Password?')}}</span>
                                                                        </a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="forgot-btn-box">
                                                        <button type="button" @click="demoUserLogin()" class="btn back-btn text-white" :class="{ 'btn-loading': demoLoading }">
                                                            {{translatedLangData('demo-login','Demo Log in')}} <span><b></b><b></b><b></b></span>
                                                        </button>
                                                        <button @click.prevent="signInMethodCall()" class="btn get-otp-btn text-white fw-bold" :class="{ 'btn-loading': loading }">
                                                            {{translatedLangData('log-in','Log in')}}
                                                            <!-- <span></span> -->
                                                            <span><b></b><b></b><b></b></span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="login-with" v-if="siteSettings?.business_type != 1">
                                        <div class="form-line"></div> <span>{{translatedLangData('or-login-with','or log in with')}}</span>
                                        <div class="form-line"></div>
                                    </div>
                                    <div class="exch-social-icon-wrapper" v-if="siteSettings && siteSettings?.social_link && siteSettings?.business_type != 1">
                                        <ul class="exch-social-icon-sec">
                                            <li>
                                                <a v-if="siteSettings?.social_link?.whatsappno1" :href="'//wa.me/'+siteSettings?.social_link?.whatsappno1" target="_blank" class="whatsapp social-media-link">
                                                    <img  src="@/assets/images/whatsapp-icon.webp" alt="whatsapp">
                                                </a>
                                            </li>
                                            <li>
                                                <a v-if="siteSettings?.social_link?.facebook_link" :href="siteSettings?.social_link?.facebook_link" target="_blank">
                                                    <img  src="@/assets/images/facebook-login.webp">
                                                </a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0);">
                                                    <img  src="@/assets/images/google-login.webp">
                                                </a>
                                            </li> -->
                                            <li>
                                                <a v-if="siteSettings?.social_link?.instagram_link" :href="siteSettings?.social_link?.instagram_link" target="_blank">
                                                    <img  src="@/assets/images/instagram-login.webp">
                                                </a>
                                            </li>
                                            <li>
                                                <a v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link.includes('https://www.') || siteSettings?.social_link?.telegram_link.includes('https://')? siteSettings?.social_link?.telegram_link : 'https://www.'+siteSettings?.social_link?.telegram_link" target="_blank">
                                                    <img  src="@/assets/images/telegram-login.webp">
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="marquee-container">
                    <div class="marquee-content">
                        <div class="bonus-box" v-if="bonusData">
                            <div :class="index % 2 == 0 ? 'bonus-card-box1' :'bonus-card-box'" v-for="(item,index) in bonusData" :key="index">
                                <img loading="lazy" src="@/assets/images/star.svg" alt="star" class="star3">
                                <div :class="index % 2 == 0 ? 'bonus-inner-card1' : 'bonus-inner-card'">
                                <img loading="lazy" src="@/assets/images/bonus-gift-img-2.webp" alt="bonus-gift-img-2" class="bonus-img">
                                <span v-if="item?.bonusType == 'register'">{{item?.modeAmount}} {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('on-register','On Registeration')}}</span>
                                <span v-if="item?.bonusType == 'welcome'">{{item?.welcomeBalance}} {{ translatedLangData('welcome-balance','Welcome Bonus')}}</span>
                                <span v-if="item?.bonusType == 'first refer&earn'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('first-refer-earn','First Refer & Earn')}}</span>
                                <span v-if="item?.bonusType == 'next 4 refer&earn'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('next-4-refer-earn','Next 4 Refer & Earn')}}</span>
                                <span v-if="item?.bonusType == 'first deposit'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('1st-deposit','1st Deposit')}}</span>
                                <span v-if="item?.bonusType == 'every deposit'">{{item?.percentage}}% {{ translatedLangData('bonus','Bonus')}} {{ translatedLangData('eveery-deposit','Every Deposit')}}</span>
                                </div>
                                <img loading="lazy" src="@/assets/images/star.svg" alt="star" class="star4">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { mapGetters } from 'vuex';
import { Modal } from "bootstrap"

export default {
    name: "Login",
    inject:['translatedLangData'],
    props:['bonusData'],
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ""
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
            passwordFieldType:'password',
            phnValid: false,
            initialPhnIcon: false,
            passwordValidation1 : false,
            passwordValidation2 : false,
            passwordFlag: true,
            passwordValidation: false,
            userIdValidation: false,
            userIdValidation1: false,
            userIdValidation2: false,
            userIdFlag: true,
            clickCountry: false,
            selectedCountryflag: 'https://flagcdn.com/in.svg',
            loggedUserCheck:null
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        })
    },
    mounted(){
        this.loggedUserCheck=localStorage.getItem('LoggedUser')
        if(this.$route.name == 'login' && !this.checkIsLogin()){
            let loginModal = new Modal('#loginModalToggle');
            loginModal.show();
        }
        else if(this.$route.name == 'login' && this.checkIsLogin()){
            this.$router.push('/')
        }
        document.addEventListener('click', this.closeDropdownOnClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
    },
    methods: {
        selectCountry(item){
            this.form.code = item?.phonecode;
            this.selectedCountryflag = item?.flag;
            this.clickCountry = false;
        },
        closeDropdownOnClickOutside(event) {
            if (this.$refs.dropdownWrapper) {
                if (!this.$refs.dropdownWrapper.contains(event.target)) {
                    this.clickCountry = false;
                }
            }
        },
        handleInputUserID(event){
            if(this.userIdFlag){
                this.userIdFlag = false;
                this.userIdValidation = true;
            }
            const containsSpaces = /\s/.test(this.form.userId); 
            if (containsSpaces) {
                this.form.userId = this.form.userId.replace(/\s/g, '');
            }
        },
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';
            }
        },
        refreshData(type) {
            this.form = {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: type,
                captchaText: ""
            },
                this.showValidationError = false,
                this.passwordValidation1 = false,
                this.passwordValidation2 = false,
                this.passwordFlag = true
                this.passwordValidation = false
                this.userIdFlag = true
                this.userIdValidation = false
                this.userIdValidation1 = false,
                this.userIdValidation2 = false
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userid = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, "demo")
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        validatePassword(){
            if(this.passwordFlag){
                this.passwordFlag = false;
                this.passwordValidation = true;
            }
            const password = this.form.password;
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(password);
            const containsAlphabets = /^(?=.*[a-zA-Z]).*$/.test(password);
            const containsNumbers = /^(?=.*\d).*$/ .test(password);
            if (containsSpaces) {
                this.form.password = password.replace(/\s/g, '');
            }
        },
        checkMobileNoLength(event) {
            this.initialPhnIcon = true;
            this.form.mobileNo = event.target.value.replace(/\D/g, ''); // Extract only numeric characters from the input value
            this.phnValid = this.form.mobileNo.length == 10;
        },
        async loginServiceCall(url, type) {
            let id = this.getId();
            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let data = {
                "userid": id.toString(),
                "type": this.siteSettings.business_type == 1 ? 2 : this.form.type,
                "password": this.form.password,
                "cncode": this.form.code,
            };
            
            api.post(url, data).then(response => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            if(this.loggedUserCheck!=userData.userid){
                                this.$store.dispatch('setClickBetValue',this.inputvalue);
                            }
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            this.$store.dispatch('setSiteVersion', null);
                            localStorage.setItem("LoggedUser",userData.userid)
                            setHeaders();
                            this.setUserDataToStore(userData);
                            this.refreshData(this.form.type);
                            location.reload();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getId() {
            if (this.siteSettings.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                rid: userData.rid,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi: userData.cpi,
                asciiCodes: asciiCodes,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        }
    }
}
</script>
<style scoped>
.cntry-drp{
    background: transparent !important;
    border: none !important;
}
.bonus-card-box span, .bonus-card-box1 span{
    width: 7.5rem;
    color: #fff;
    font-size: .9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: .01875rem;
}
</style>