const state = {
    profitLossItem: null,
    statementItem: null,
    viewBetsItem:null
};
const getters = {
    profitLossItem: state => state?.profitLossItem,
    statementItem: state => state?.statementItem,
    viewBetsItem: state => state?.viewBetsItem,
};
const actions = {
    setProfitLossItem({ commit }, profitLossItem) {
        commit('setProfitLossItem', profitLossItem);
    },
    setStatementItem({ commit }, statementItem) {
        commit('setStatementItem', statementItem);
    },
    setViewBetsItem({ commit }, viewBetsItem) {
        commit('setViewBetsItem', viewBetsItem);
    },
};
const mutations = {
    setProfitLossItem(state, profitLossItem) {
        state.profitLossItem = profitLossItem;
    },
    setStatementItem(state, statementItem) {
        state.statementItem = statementItem;
    },
    setViewBetsItem(state, viewBetsItem) {
        state.viewBetsItem = viewBetsItem;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};