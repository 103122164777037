const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const Statements = () => import(/* webpackChunkName: "statements" */ './views/Statements.vue');
const StatementDetails = () => import(/* webpackChunkName: "statement-detail" */ './views/StatementDetail.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const ProfitLossDetails = () => import(/* webpackdhunkname: "profit-loss-detail" */ './views/ProfitLossDetail.vue');
const DepositWithdrawReport = () => import(/* webpackdhunkname: "profit-loss-detail" */ './views/DepositWithdrawReport.vue');
const MatchMarket = () => import(/* webpackChunkName: "match-market" */ './views/MatchMarket.vue');

const BetsRoutes =   {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'open-bets',
            name: 'bets',
            component: Bets,
            meta: {
                title: 'Open Bets'
            }
        },
        {
            path: 'match-market/:item/:type',
            name: 'match-market',
            component: MatchMarket,
            meta: {
                title: 'Open Bets Details'
            }
        },
        {
            path: 'statements',
            name: 'statements',
            component: Statements,
            meta: {
                title: 'Account Statement'
            }
        },
        {
            path: 'statement-detail',
            name: 'statement-detail',
            component: StatementDetails,
            meta: {
                title: 'Account Statement Details'
            }
        },
        {
            path: 'profit-loss',
            name: 'profit-loss',
            component: ProfitLoss,
            meta: {
                title : 'Betting Profit Loss'
            }
        },
        {
            path: 'profit-loss-detail',
            name: 'profit-loss-detail',
            component: ProfitLossDetails,
            meta: {
                title: 'Betting Profit Loss Details'
            }
        },
        {
            path: 'deposit-withdraw-report',
            name: 'deposit-withdraw-report',
            component: DepositWithdrawReport,
            meta: {
                title: 'Deposit Withdraw Report'
            }
        }
    ],
}

export default BetsRoutes;