<template>
    <div class="trending-all-gms-wrapper">
        <div class="container">
            <div class="trending-all-gms-sec">
                <a @click="gotoSports(sport)" :id="'nav-' + sport.slug +'-tab'"
                    v-for="sport in sportList?.slice(0, 9)" :key="sport.id" v-show="checkInEventMange(sport.slug)">
                    <div class="trending-all-sport-opt" :class="getSportClass(sport)">
                        <img :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" alt="">
                        <span class="title-all-sport">{{  translatedLangData(sport.slug,getCapitalizedText(sport.name)) }}</span>
                    </div>
                </a>
                <div class="trending-all-sport-opt" @click="gotoMore()">
                    <img src="@/assets/images/more.webp" alt="more-arrow" style="min-width: 20px; width: 20px; height: auto; min-height: auto;">
                    <span class="title-all-sport">{{translatedLangData('more','More')}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- --More Sports Modal --- -->

    <div class="modal fade logoutmodal" id="sportsmodalToggle" aria-hidden="true" aria-labelledby="sportsmodalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-end">
            <div class="modal-content transparent-modal-content">
                <div class="e-p-content-bc1">
                    <div class="e-p-body-bc" style="overflow-y: auto; margin: 0px;" >
                        <div class="login-page-abc">
                            <div class="login-page ">
                                <div class="login-box">
                                    <div class="login-card">
                                        <div class="login-card-header exch-modal-popup-header-section">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin: 0 0 0 auto;">
                                                <i class="fa fa-close"></i>
                                            </button>
                                        </div>
                                        <div class="exch-cashout-body-center">
                                            <div class="trending-all-gms-wrapper">
                                                <div class="trending-all-gms-sec">
                                                    <a @click="gotoSports(sport)" :id="'nav-' + sport.slug +'-tab'" 
                                                        v-for="sport in sportList" :key="sport.id">
                                                        <div class="trending-all-sport-opt" :class="getSportClass(sport)">
                                                            <img :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" alt="">
                                                            <span class="title-all-sport">{{  translatedLangData(sport.slug,getCapitalizedText(sport.name)) }}</span>
                                                        </div>
                                                    </a>
                                                    <a @click="gotoSports(sport)" :id="'nav-' + sport.slug +'-tab'" 
                                                        v-for="sport in customSportList" :key="sport.id">
                                                        <div class="trending-all-sport-opt" :class="getSportClass(sport)">
                                                            <img :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" alt="">
                                                            <span class="title-all-sport">{{ getCapitalizedText(sport.name) }}</span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from 'bootstrap';
export default {
    name: "SportSelection",
    inject:['translatedLangData'],
    
    data: () => ({
        getSportsName: null,
        eventManage: null
    }),
    computed:{
        sportList(){
            return this.$store.getters.sportsData?.non_custom.sort((a, b) => a.rank - b.rank);
        },
        customSportList(){
            return this.$store.getters.sportsData?.custom
        }
    },
    mounted() {
        this.eventManage = this.$store?.getters?.eventManage;
        this.getSportsName = this.$route.params.type;
    },
    methods: {
        gotoMore(){
            let moreModal = new Modal(document.getElementById('sportsmodalToggle'))
            if(moreModal){
                moreModal.show();
            }
        },
        gotoSports(sport){
            let allModals = document.querySelectorAll('.modal.show');
            if(allModals.length) {
                allModals.forEach(mol => {
                    let modalInstance = Modal.getInstance(mol);
                    modalInstance.hide();
                })
            }
            this.$router.push('/sports/' + sport.slug + '/' + sport.id)
        },
        checkInEventMange(type) {
            return this.eventManage && this.eventManage?.length > 0 ? this.eventManage?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        getIcon(id){
            let imgMap = {
                4 : 'cricket.png',
                1 : 'sidemenu6.webp',
                2 : 'table-tennis.png',
                6423 : 'American- Football.webp',
                61420 : 'Australian-Rules.svg',
                7511 : 'Baseball.webp',
                7522 : 'basketball.png',
                6 : 'boxing-glove.png',
                11 : 'cycling.png',
                3503 : 'Darts.webp',
                27454571 : 'esportspng.png',
                2152880 : 'gaelic-games.svg',
                3 : 'golf.png',
                4339 : 'Greyhound-Racing.webp',
                468328 : 'Handball.webp',
                7 : 'Horse-Racing-1.webp',
                7524 : 'ice-hocky.webp',
                26420387 : 'martial-arts.png',
                8 : 'moto-sports.png',
                2378961 : 'Politics.png',
                1477 : 'Rugby.webp',
                5 : 'Rugby-Union.webp',
                6422 : 'Snooker.webp',
                10 : 'special-bets.png',
                998917 : 'volleyball.png'

            }
            return imgMap[id] || 'game_default_icon.svg';
        },
        getCapitalizedText(text) {
            return text[0].toUpperCase() + text.substring(1)
        },
        getSportClass(sport) {
            if(this.getSportsName == sport.slug) {
                if(sport.id == 1 || sport.id == 2 || sport.id == 4) {
                    return `${sport.slug}-sport active`;
                } else {
                    return `cricket-sport active`;
                }
            }
        }
    }
};
</script>